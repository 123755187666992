/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

.vjs-theme-fantasy .vjs-play-progress,
.vjs-theme-fantasy .vjs-play-progress:before {
  --vjs-theme-fantasy--primary: #f25555;
  --vjs-theme-fantasy--secondary: white;
}

.vjs-theme-fantasy .vjs-big-play-button {
  --vjs-theme-fantasy--primary: #f25555;
  --vjs-theme-fantasy--secondary: white;
}

html {
  height: -webkit-fill-available;
  scroll-behavior: smooth;
}
html[lang="en"] {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  // --mdc-dialog-subhead-font: Roboto, "Helvetica Neue", sans-serif;
  // --mdc-dialog-supporting-text-font:  Roboto, "Helvetica Neue", sans-serif;
}
html[lang="ar"] {
  font-family: Cairo;
  --mdc-dialog-subhead-font: Cairo;
  --mdc-dialog-supporting-text-font: Cairo;
}
html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}
body {
  margin: 0;
  min-height: -webkit-fill-available;
  height: 100%;
}

.bt-bg-dark {
  background-color: #262626;
}

.hover\:bt-bg-dark:hover {
  background-color: #262626;
}

.bt-text-dark {
  color: #262626;
}

.bt-border-dark {
  border-color: #262626;
}

.hover\:bt-border-dark:hover {
  border-color: #262626;
}

.bt-bg-red {
  @apply bg-primary;
  // background-color: #fe0471;
}

.hover\:bt-bg-red:hover {
  @apply bg-primary;
  // background-color: #fe0471;
}

.bt-text-red {
  @apply text-primary;
  // color: #fe0471;
}

.hover\:bt-text-red:hover {
  @apply text-primary;
  // color: #fe0471;
}

.bt-border-red {
  @apply border-primary;
  // border-color: #fe0471;
}

.hover\:bt-border-red:hover {
  @apply border-primary;
}

.bt-bg-orange {
  @apply border-primary-light;
}

.hover\:bt-bg-orange:hover {
  background-color: #ff6625;
}

.bt-text-orange {
  color: #ff6625;
}

.hover\:bt-text-orange:hover {
  @apply border-primary-light;
}

.bt-border-orange {
  @apply border-primary;
}

.hover\:bt-border-orange:hover {
  @apply border-primary-light;
}

.dialog-no-px .mat-dialog-container {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.dialog-p-4 .mat-dialog-container {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-top: 16px;
}

.bg-red-gradient {
  // @apply  bg-gradient-to-tl from-primary-dark to-primary-light;
  background: linear-gradient(-45deg, #0271ee, #00c6ff);
}

.loading-gradient {
  background: linear-gradient(-45deg, #e8e8e8, #d4d4d4);
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;
}

.loading-gradient-dark {
  background: linear-gradient(-45deg, #e8e8e8, #d4d4d4);
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.w-available {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

@media (min-width: 768px) {
  .md\:w-3\/5 {
    width: 60%;
  }
}

arabic-typography {
  // font-family: "Amiri", serif;
}

*::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  // background-color: #000000;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
  // background-color: #000000;
}

*::-webkit-scrollbar-thumb {
  border-radius: 2px;
  // -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, .3);
  // background-color: #fafafa;
  background-color: rgb(209 213 219);
}

div::-webkit-scrollbar {
  width: 4px !important;
}

div::-webkit-scrollbar-thumb {
  // background-color: #fafafa !important
}

.mat-mdc-snack-bar-container {
  &.error-snackbar {
    --mdc-snackbar-container-color: rgb(211, 47, 47);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
  &.success-snackbar {
    --mdc-snackbar-container-color: rgb(46, 125, 50);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }

  &.warn-snackbar {
    --mdc-snackbar-container-color: rgb(237, 108, 2);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }

  &.primary-snackbar {
    --mdc-snackbar-container-color: rgb(2, 136, 209);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

.mat-mdc-tab-body-wrapper {
  @apply flex-1;
}

.textfield {
  .label {
    @apply block text-sm text-gray-700 mb-1;
  }
  .input {
    @apply block w-full rounded text-black border border-gray-500 px-3 py-3 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm;
  }
  .validation {
    @apply text-sm text-red-600;
  }
}

.mat-mdc-menu-item {
  display: flex !important;
  justify-content: space-between !important;
  .mat-icon {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.cdk-global-overlay-wrapper {
  direction: inherit;
}

.ngx-editor {
  /* to penetrate <ngx-editor> CSS styling */

  ul {
    list-style: disc;
    padding-left: 40px;
  }

  ol {
    list-style: decimal;
    padding-left: 40px;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 1.5rem 0 0.5rem;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 1.25rem 0 0.5rem;
  }

  h3 {
    font-size: 1.125rem;
    font-weight: 500;
    margin: 1.125rem 0 0.5rem;
  }

  h4 {
    font-size: 1rem;
    font-weight: 500;
    margin: 1rem 0 0.5rem;
  }

  h5 {
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0.875rem 0 0.5rem;
  }

  h6 {
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0.75rem 0 0.5rem;
  }
}

.button {
  @apply focus:outline-none focus:ring-0 font-medium rounded-full text-sm md:text-base px-3 py-2 md:px-5 md:py-2.5 text-center flex items-center
}
.button.primary {
  @apply text-white bg-primary hover:bg-primary-dark
}
.button.white {
  @apply text-primary bg-white hover:bg-primary-extra-light
}

.page-max-width{
  @apply max-w-screen-xl mx-auto;
}
.page-max-width.page-default-padding{
  @apply px-6;
}

.input{
  @apply bg-white border border-gray-300 text-gray-900 rounded-full focus:outline-none focus:border-primary text-sm md:text-base py-2 md:py-2.5 px-4
}

@keyframes banner-animation {
  0% {
    transform: translateY(0px);
  }
  40% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-64px);
  }
  90% {
    transform: translateY(-64px);
  }
  100% {
    transform: translateY(0px);
  }
}
